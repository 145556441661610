function KikiBookService() {
    return (
        <div>
            <p>
                transfer all reviews/notes into here.
            </p>
        </div>
        );
}

export default KikiBookService;